import { CSSProperties, ReactNode, forwardRef } from "react";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import { Space } from "./Space";
import BodyText from "./typography/bodyText";
import LabelText from "./typography/labelText";

type ListItemProps = {
  leftAccessory?: ReactNode;
  rightAccessory?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  cursor?: CSSProperties["cursor"];
  width?: CSSProperties["width"];
} & (
  | {
      onClick?: undefined;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name"?: string;
    }
  | {
      onClick: () => void;
      /**
       * The name will get used for automated product events.
       * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
       */
      "data-name": string;
    }
);

export const ListItem = forwardRef<HTMLButtonElement, ListItemProps>(
  ({ leftAccessory, rightAccessory, title, description, cursor, onClick, width, "data-name": dataName }, ref) => {
    if (!dataName || !onClick) {
      return (
        <span ref={ref} sx={{ display: "flex", alignItems: "center", position: "relative", width, cursor }}>
          {leftAccessory && <ListItemLeftAccessory>{leftAccessory}</ListItemLeftAccessory>}
          {(title || description) && <TextSection title={title} description={description} />}
          {rightAccessory && <ListItemRightAccessory>{rightAccessory}</ListItemRightAccessory>}
        </span>
      );
    }

    return (
      <UnstyledButton
        ref={ref}
        onClick={onClick}
        data-name={dataName}
        sx={{ display: "flex", alignItems: "center", position: "relative", width, cursor }}
      >
        {leftAccessory && <ListItemLeftAccessory>{leftAccessory}</ListItemLeftAccessory>}
        {(title || description) && <TextSection title={title} description={description} />}
        {rightAccessory && <ListItemRightAccessory>{rightAccessory}</ListItemRightAccessory>}
      </UnstyledButton>
    );
  },
);

export const ListItemTitle = ({ children, nowrap }: { children: ReactNode; nowrap?: boolean }) => {
  if (typeof children === "string") {
    return <LabelText nowrap={nowrap || undefined}>{children}</LabelText>;
  } else {
    return children ? <>{children}</> : null;
  }
};
ListItemTitle.displayName = "ListItemTitle";

export const ListItemDescription = ({ children }: { children: ReactNode }) => {
  if (typeof children === "string") {
    return <BodyText nowrap>{children}</BodyText>;
  } else {
    return children ? <>{children}</> : null;
  }
};
ListItemDescription.displayName = "ListItemDescription";

const TextSection = ({ title, description }: { title?: ReactNode; description?: ReactNode }) => {
  return (
    <div sx={{ minWidth: "0", position: "relative", display: "flex", flexDirection: "column" }}>
      <ListItemTitle>{title}</ListItemTitle>
      {description && <ListItemDescription>{description}</ListItemDescription>}
    </div>
  );
};

const ListItemLeftAccessory = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div sx={{ display: "flex", alignItems: "center", position: "relative", justifyContent: "space-between" }}>
        {children}
      </div>
      <Space kind="inline" size="xs" />
    </>
  );
};
ListItemLeftAccessory.displayName = "ListItemLeftAccessory";

const ListItemRightAccessory = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div sx={{ display: "flex", flexGrow: 1, position: "relative" }} />
      <Space kind="inline" size="s" />
      {children}
    </>
  );
};
ListItemRightAccessory.displayName = "ListItemRightAccessory";
