import { css } from "@emotion/react"; // eslint-disable-line no-restricted-imports
import { Root, Trigger, Item, Content, Portal } from "@radix-ui/react-dropdown-menu";
import React, { useEffect, useMemo, useState } from "react";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useScrollDetector from "../../hooks/useScrollDetector";
import useStatefulRef from "../../hooks/useStatefulRef";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { GlobalCSS } from "../../nessie/stylingLib";
import { DownCarrotIcon } from "../icons";
import { NessieThemeColors } from "./theme";
import { Link } from "react-router-dom";
import { isCypressTest } from "../../utils/env";
import { announceToScreenReader } from "@web-monorepo/dds";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export interface MenuOptions {
  label: string;
  onClick?: () => void;
  // this is very important to keep the menu open while opening a modal.
  // keyboard users need to be brought back to the menu when the modal closes.
  opensAModal?: boolean;
  color?: NessieThemeColors;
  icon?: React.ReactElement;
  href?: string;
  to?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
}
interface DropdownMenuProps {
  align?: "center" | "end" | "start";
  caret?: boolean;
  color?: NessieThemeColors;
  side?: "top" | "right" | "bottom" | "left";
  label: string;
  trigger?: React.ReactNode;
  options: MenuOptions[];
  disabled?: boolean;
  optionsContainerMaxHeight?: string | number;
  onOpen?: () => void;
  /**
   * this prop allow you to choose the container of the menu. Defaults to body.
   */
  portalContainer?: HTMLElement | null;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  "data-testid"?: string;
  "data-experiments"?: string[];
}

interface Item {
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string | null;
}

const isPointerEvent = (e: Event): e is PointerEvent => {
  return "pointerType" in e;
};

const getScrollParent = (node?: Element | null): Element | null => {
  if (!node) {
    return null;
  }

  if (window.getComputedStyle(node).position === "absolute") {
    let positionParent = node.parentElement;

    while (!!positionParent && window.getComputedStyle(positionParent).position === "static") {
      positionParent = positionParent.parentElement;
    }

    return getScrollParent(positionParent);
  }

  const overflowY = window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

  if (node.scrollHeight > node.clientHeight && isScrollable) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
};

export function DropdownMenu({
  label,
  trigger,
  options,
  color,
  side,
  caret,
  align,
  disabled,
  optionsContainerMaxHeight,
  portalContainer,
  onOpen,
  "data-name": dropdownDataName,
  "data-testid": dataTestId,
  "data-experiments": dataExperiments,
}: DropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = useStatefulRef<HTMLButtonElement>();

  const { current: triggerEl } = triggerRef;
  const scrollParent = useMemo(() => {
    return getScrollParent(triggerEl);
  }, [triggerEl]);

  const isIntersecting = React.useRef<boolean | undefined>();
  isIntersecting.current = useIntersectionObserver(triggerRef, { root: scrollParent, threshold: 1 });

  const handleEscape = React.useCallback((event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  }, []);

  const handleFocusOutside = React.useCallback((e: Event) => e.preventDefault(), []);

  const onScroll = React.useCallback(() => {
    if (
      // auto-closing the dropdown menu causes a lot of flaky tests on cypress
      // due slight scrolling of the page when loading.
      // test finds the dropdown -> opens the dropdown -> (auto-close might happen here) -> selects one of the options (fails)
      !isCypressTest &&
      isIntersecting.current === false &&
      isOpen
    ) {
      setIsOpen(false);
    }
  }, [isIntersecting, isOpen]);

  useScrollDetector(onScroll);

  useEffect(() => {
    if (isOpen) {
      announceToScreenReader(autoTranslate("Menu expanded"));
    } else {
      announceToScreenReader(autoTranslate("Menu collapsed"));
    }
  }, [isOpen]);

  return (
    // this on key down is necessary to handle the escape to close
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} onKeyDown={handleEscape}>
      <GlobalCSS
        styles={css`
          [data-radix-popper-content-wrapper]:focus-within {
            z-index: 1000 !important;
          }
        `}
      />
      <Root
        open={isOpen}
        onOpenChange={() => {
          if (!isOpen && onOpen) {
            onOpen();
          }
          setIsOpen(!isOpen);
        }}
        modal={false}
      >
        <Trigger ref={triggerRef} sx={DropdownMenuTriggerButtonStyles} asChild disabled={disabled}>
          <UnstyledButton
            sx={{ ...DropdownMenuTriggerButtonStyles, color }}
            type="button"
            aria-label={label}
            aria-expanded={isOpen}
            data-name={dropdownDataName}
            data-experiments={dataExperiments}
            data-testid={dataTestId}
            disabled={disabled}
            onKeyDown={(e) => {
              // without this, the click with enter also clicks on the first option and never opens the options
              if (!isOpen && e.key === "Enter") {
                e.preventDefault();
                setIsOpen(true);
              }
            }}
          >
            {trigger}
            {(caret || !trigger) && !disabled ? (
              <div className={"DropdownMenuCarretIconSvg"}>
                <DownCarrotIcon size="s" color={color} />
              </div>
            ) : null}
          </UnstyledButton>
        </Trigger>
        <Portal container={portalContainer}>
          <Content
            role="menu"
            loop
            side={side}
            sx={{
              ...optionsListStyles,
              maxHeight: optionsContainerMaxHeight || "var(--radix-popper-available-height)",
            }}
            align={align}
            onFocusOutside={handleFocusOutside}
            ref={contentRef}
          >
            <ul
              sx={{
                listStyle: "none",
                margin: 0,
                padding: 0,
              }}
            >
              {options.map((option) => {
                let isMouse = false;
                const Tag = option.to ? Link : option.href ? "a" : "button";

                return (
                  <li key={option.label} sx={listItemContainerStyles}>
                    <Item
                      onSelect={(e) => {
                        // Keyboard users need the menu to keep open when they open a modal.
                        // According to a11y regulation, the focus should return to the button that opened the modal once said modal is closed
                        // The only way to do that is by keeping the menu open.
                        // Radix allows us to keep the menu open by adding prevent default to the onSelect prop.
                        // The problem is that the radix menu items interprets a click with a mouse differently from a click with a keyboard
                        // because of that, it only moves focus to the opened modal if the click is done with a keyboard.
                        // on top of that, radix does not pass any property to the onSelect event object that allows us to verify if it was a mouse click or a keyboard click.
                        // so, to fix that, we had to add an onClick listener to grab the pointer type
                        if (option.opensAModal && !isMouse) e.preventDefault();
                        if (isMouse) isMouse = false;
                      }}
                      onClick={(e) => {
                        if (isPointerEvent(e.nativeEvent) && e.nativeEvent.pointerType) {
                          isMouse = true;
                        }
                      }}
                      asChild
                      role={option.href ? "link" : "button"}
                      data-name={`${dropdownDataName}:${option["data-name"]}`}
                      aria-label={option.label}
                      data-experiments={dataExperiments}
                    >
                      <Tag
                        sx={{ ...listItemStyles, color: option.color || "dt_content_primary" }}
                        onClick={option.onClick}
                        href={option.href}
                        // See above, the Tag is a Link if and only if option.to is defined, so the non-null assertion is safe
                        to={option.to!}
                      >
                        {option.icon}
                        {option.label}
                      </Tag>
                    </Item>
                  </li>
                );
              })}
            </ul>
          </Content>
        </Portal>
      </Root>
    </div>
  );
}

const DropdownMenuTriggerButtonStyles: ThemeUIStyleObject = {
  fontWeight: "600",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  minWidth: "40px",
  minHeight: "40px",
  '&[aria-expanded="true"] .DropdownMenuCarretIconSvg': {
    transform: "rotate(180deg)",
  },
  '&[disabled="true"],  &[disabled]': {
    cursor: "not-allowed",
    opacity: "0.6",
  },
  ".DropdownMenuCarretIconSvg": {
    transition: "transform 0.3s linear",
    marginLeft: "dt_xxs",
    display: "flex",
    alignItems: "center",
  },
};

const optionsListStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  boxShadow: RAW_cssValue("0 1px 5px 0px rgba(0, 0, 0, 0.3)"),
  marginTop: "dt_xs",
  borderRadius: "dt_radius_s",
  overflowY: "auto",
  pointerEvents: "auto",
  // Required to appear in front of radix modals, it's possible
  // there's a version mismatch in popper?
  zIndex: "1000",
};

const listItemContainerStyles: ThemeUIStyleObject = {
  gap: "dt_s",
  // this needs to be last child and not last of type now that we have two different types as children
  "&:not(:last-child)": {
    borderBottom: "dt_divider",
  },
  "&:last-child": {
    borderBottomLeftRadius: "dt_radius_s",
    borderBottomRightRadius: "dt_radius_s",
    "> *": {
      borderBottomLeftRadius: "dt_radius_s",
      borderBottomRightRadius: "dt_radius_s",
    },
  },
  "&:first-child": {
    borderTopLeftRadius: "dt_radius_s",
    borderTopRightRadius: "dt_radius_s",
    "> *": {
      borderTopLeftRadius: "dt_radius_s",
      borderTopRightRadius: "dt_radius_s",
    },
  },
};

const listItemStyles: ThemeUIStyleObject = {
  backgroundColor: "transparent",
  "&:hover, &:focus-within": {
    backgroundColor: "dt_background_secondary",
  },
  border: "none",
  fontSize: "16px",
  paddingLeft: "dt_s",
  paddingRight: "dt_xl",
  paddingY: "dt_s",
  width: "100%",
  display: "flex",
  alignItems: "center",
};
