import { useState, useRef } from "react";
import { addBreadcrumb } from "@web-monorepo/telemetry";
import { CameraVideoIcon } from "../../nessie/icons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { UploadType } from "../../utils/uppy/uploadConfigs";
import { UnstyledButton } from "../buttons";
import ChevronButton from "../misc/ChevronButton";
import FileAttachment from "../misc/FileAttachment";
import Video from "../misc/Video";
import PhotoModal from "../modals/Photo";
import useWatch from "../../hooks/useWatch";

import { AT, autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { CaptionUploader } from "./CaptionUploader";
import { AltTextInput } from "./AltTextInput";

export type Attachment = {
  _id?: string;
  type: UploadType;
  path: string;
  metadata?: {
    contentType?: string;
    filename?: string;
    captionsByLanguage?: Record<string, string>;
    altText?: string;
  };
  contentType?: string;
  sources?: Array<{ path: string; contentType: string }>;
  transcoding?: boolean;
  thumbnailPath?: string;
};

type AttachmentsProps = {
  attachments: Attachment[];
  hideDownloadLinkOnVideo?: boolean;
  visible?: boolean;
  logEvent: (eventName: string) => void;
  canPlayWebm: boolean;
  senderName?: string;
  addCaptionsFile?: (attachmentId: string, url: string) => void;
  addAltTextToPhotoAttachment?: (attachmentId: string, altText: string) => void;
};

type AttachmentContentProps = Omit<AttachmentsProps, "attachments"> & {
  currentAttachment: Attachment;
};

const appendWidth = (path: string) => {
  if (path.indexOf("?") < 0) {
    return `${path}?w=500`;
  }
  return `${path}&w=500`;
};

const PhotoAttachment = ({
  currentAttachment,
  logEvent,
  setShowingExpandedPhoto,
  addAltTextToPhotoAttachment,
}: AttachmentContentProps & { setShowingExpandedPhoto: (state: boolean) => void }) => {
  // Don't attempt to render images from the web-server
  const urlPath = /^https?:\/\//.test(currentAttachment.path)
    ? appendWidth(currentAttachment.path)
    : currentAttachment.path;

  const [showAltTextUploader, setShowAltTextUploader] = useState(false);
  useWatch(currentAttachment, () => setShowAltTextUploader(false), { immediate: false });

  return (
    <div>
      <UnstyledButton
        data-name="storyPostImage"
        sx={MEDIA_STYLE}
        onClick={() => {
          logEvent("class_story.post_photo.open");
          setShowingExpandedPhoto(true);
        }}
        aria-label={currentAttachment.metadata?.altText ? undefined : autoTranslate("Open image")}
      >
        <img
          src={urlPath}
          alt={currentAttachment.metadata?.altText || undefined}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "50% 50%",
            boxShadow: "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
          }}
        />
      </UnstyledButton>
      {addAltTextToPhotoAttachment && !showAltTextUploader && (
        <UnstyledButton
          data-name="show-alt-text-uploader"
          onClick={() => setShowAltTextUploader(true)}
          sx={{
            position: "absolute",
            bottom: "15px",
            left: "15px",
            backgroundColor: "dt_background_media",
            padding: "dt_s",
            borderRadius: "dt_radius_s",
            color: "dt_content_light",
          }}
        >
          {currentAttachment.metadata?.altText ? autoTranslate("Replace alt text") : autoTranslate("Add alt text")}
        </UnstyledButton>
      )}
      {addAltTextToPhotoAttachment && currentAttachment._id && showAltTextUploader && (
        <AltTextInput
          ref={(r: null | HTMLDivElement) =>
            setTimeout(() => r?.querySelector("textarea")?.focus({ preventScroll: false }), 10)
          }
          attachment={currentAttachment}
          onAddAltText={addAltTextToPhotoAttachment}
          onClose={() => setShowAltTextUploader(false)}
        />
      )}
    </div>
  );
};

const VideoAttachment = ({
  currentAttachment,
  logEvent,
  videoStarted,
  canPlayWebm,
  hideDownloadLinkOnVideo,
  visible,
  senderName,
  addCaptionsFile,
}: AttachmentContentProps & { videoStarted: React.MutableRefObject<boolean> }) => {
  const sources = transformVideoSources(currentAttachment);
  const [showCaptionUploader, setShowCaptionUploader] = useState(false);

  useWatch(currentAttachment, () => setShowCaptionUploader(false), { immediate: false });

  const onVideoPlay = () => {
    if (!videoStarted.current) {
      logEvent("class_story.post_video.start");
      videoStarted.current = true;
    } else {
      logEvent("class_story.post_video.resume");
    }
  };

  const onVideoPause = () => {
    logEvent("class_story.post_video.pause");
  };

  return (
    <div sx={MEDIA_STYLE}>
      {!canPlayWebm && !!currentAttachment.transcoding && (
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "dt_s",
            backgroundColor: RAW_cssValue("#4B4FA1"),
            position: "absolute",
            zIndex: "100",
            width: "100%",
          }}
        >
          <div sx={{ position: "relative" }}>
            <span sx={VIDEO_TRANSCODING_TITLE}>
              <AT>Preparing video</AT>
            </span>
            <span sx={VIDEO_TRANSCODING_SUBTITLE}>
              <AT>This usually takes a few minutes</AT>
            </span>
          </div>
          <CameraVideoIcon color="dt_content_light" />
        </div>
      )}
      <Video
        aria-label={autoTranslate("Play __sender__'s video", { sender: senderName || "" })}
        controlsList={hideDownloadLinkOnVideo ? "nodownload" : ""}
        sources={sources}
        poster={currentAttachment.thumbnailPath}
        captionsByLanguage={currentAttachment.metadata?.captionsByLanguage}
        onPlay={onVideoPlay}
        onPause={onVideoPause}
        preload={visible ? "auto" : "none"}
        onError={(event) => {
          const error = (event && event.target && "error" in event.target && event.target.error) || undefined;
          addBreadcrumb({
            type: "",
            level: "error",
            event_id: "story.video.error",
            category: "story.video",
            message: "Error loading video attachment on story post",
            data: {
              currentAttachment,
              metadata: currentAttachment.metadata,
              error: error ?? "unknown",
              online: String(typeof window !== "undefined" ? (window?.navigator?.onLine ?? "unknown") : "unknown"),
            },
          });
        }}
      />
      {addCaptionsFile && !showCaptionUploader && (
        <UnstyledButton
          data-name="show-caption-uploader"
          onClick={() => setShowCaptionUploader(true)}
          sx={{
            position: "absolute",
            bottom: "15px",
            left: "15px",
            backgroundColor: "dt_background_media",
            padding: "dt_s",
            borderRadius: "dt_radius_s",
            color: "dt_content_light",
          }}
        >
          {currentAttachment.metadata?.captionsByLanguage
            ? autoTranslate("Replace captions")
            : autoTranslate("Add captions")}
        </UnstyledButton>
      )}
      {addCaptionsFile && currentAttachment._id && showCaptionUploader && (
        <CaptionUploader
          ref={(r: null | HTMLDivElement) =>
            setTimeout(() => r?.querySelector("input")?.focus({ preventScroll: false }), 10)
          }
          attachment={currentAttachment}
          onCaptionUploaded={addCaptionsFile}
          onClose={() => setShowCaptionUploader(false)}
        />
      )}
    </div>
  );
};

const fileAttachment = ({ currentAttachment }: AttachmentContentProps) => {
  return <FileAttachment {...currentAttachment.metadata} path={currentAttachment.path} />;
};

const attachmentContentFor = {
  photo: PhotoAttachment,
  video: VideoAttachment,
  file: fileAttachment,
} as const;

const Attachments = ({
  attachments,
  canPlayWebm,
  hideDownloadLinkOnVideo,
  visible,
  logEvent,
  senderName,
  addCaptionsFile,
  addAltTextToPhotoAttachment,
}: AttachmentsProps) => {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);
  const [showingExpandedPhoto, setShowingExpandedPhoto] = useState(false);
  const videoStarted = useRef(false);

  useWatch([attachments.length], () => {
    // if attachment length changes, reset index to zero to ensure we have a valid index
    setCurrentAttachmentIndex(0);
  });

  if (!attachments.length) {
    return null;
  }
  const currentAttachment = attachments[currentAttachmentIndex];

  const currentAttachmentType = currentAttachment && currentAttachment.type;

  const AttachmentComponent =
    currentAttachmentType && currentAttachmentType in attachmentContentFor
      ? attachmentContentFor[currentAttachmentType as keyof typeof attachmentContentFor]
      : () => null;

  return (
    <div sx={{ position: "relative" }}>
      <AttachmentComponent
        {...{
          currentAttachment,
          logEvent,
          setShowingExpandedPhoto,
          canPlayWebm,
          hideDownloadLinkOnVideo,
          visible,
          videoStarted,
          senderName,
          addCaptionsFile,
          addAltTextToPhotoAttachment,
        }}
      />
      {attachments[currentAttachmentIndex - 1] ? (
        <ChevronButton
          direction="left"
          sx={{
            position: "absolute",
            top: "50%",
            left: "5%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          aria-label={autoTranslate("Previous")}
          onClick={() => setCurrentAttachmentIndex(currentAttachmentIndex - 1)}
          data-name="post_contents_text_and_attachment:previous"
        />
      ) : null}
      {attachments[currentAttachmentIndex + 1] ? (
        <ChevronButton
          direction="right"
          sx={{
            position: "absolute",
            top: "50%",
            right: "5%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          aria-label={autoTranslate("Next")}
          onClick={() => setCurrentAttachmentIndex(currentAttachmentIndex + 1)}
          data-name="post_contents_text_and_attachment:next"
        />
      ) : null}
      {attachments.length > 1 ? (
        <div
          sx={{
            color: "dt_content_light",
            background: "rgba(0, 0, 0, 0.5)",
            position: "absolute",
            top: 18,
            right: 18,
            borderRadius: "dt_radius_s",
            paddingY: "dt_xs",
            paddingX: "dt_s",
          }}
        >
          <AT>
            {currentAttachmentIndex + 1} / {attachments.length}
          </AT>
        </div>
      ) : null}
      {currentAttachment && showingExpandedPhoto && (
        <MediaAttachmentModal
          currentAttachment={currentAttachment}
          onClose={() => setShowingExpandedPhoto(false)}
          left={
            attachments[currentAttachmentIndex - 1]
              ? () => setCurrentAttachmentIndex(currentAttachmentIndex - 1)
              : undefined
          }
          right={
            attachments[currentAttachmentIndex + 1]
              ? () => setCurrentAttachmentIndex(currentAttachmentIndex + 1)
              : undefined
          }
        />
      )}
    </div>
  );
};

export default Attachments;

function transformVideoSources(videoAttachment: Attachment) {
  const firstSource = {
    path: videoAttachment.path,
    contentType:
      videoAttachment.contentType || (videoAttachment.metadata && videoAttachment.metadata.contentType) || "video/mp4",
  };
  const sources = videoAttachment.sources ? [firstSource, ...videoAttachment.sources] : [firstSource];
  return sources;
}

// eslint-disable-next-line react-refresh/only-export-components
const MediaAttachmentModal = ({
  currentAttachment,
  onClose,
  left,
  right,
}: {
  currentAttachment: Attachment;
  onClose: () => void;
  left?: () => void;
  right?: () => void;
}) => {
  const { type } = currentAttachment;

  if (type === "photo" || type === "video") {
    return (
      <PhotoModal
        data-name="post_contents_text_and_attachment:media_attachment_modal:photo_modal"
        src={type === "photo" ? currentAttachment.path : undefined}
        sources={type === "video" ? transformVideoSources(currentAttachment) : undefined}
        poster={type === "video" ? currentAttachment.thumbnailPath : undefined}
        altText={currentAttachment.metadata?.altText}
        close={onClose}
        left={left}
        right={right}
      />
    );
  } else {
    return null;
  }
};

const MEDIA_STYLE = {
  width: "100%",
  height: "50rem",
  margin: RAW_cssValue("0 -1px"),
  cursor: "pointer",
  position: "relative" as const,
};

const VIDEO_TRANSCODING_TITLE: ThemeUIStyleObject = {
  color: "dt_content_light",
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "18px",
  display: "block",
};
const VIDEO_TRANSCODING_SUBTITLE: ThemeUIStyleObject = {
  color: "dt_content_light",
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "18px",
};
